@import 'Styles/Pahlmans/includes';

/**
*
* Pagination
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Pagination.Base.module.scss';

.Pagination {
    $root: &;

    padding-top: 20px;
    padding-bottom: 28px;
    color: $text-color;
    transition: opacity .2s;

    &--Loading {
        opacity: 0;
    }

    &__Item {
        background-color: white;
        margin: 0;
        font-size: 1.6rem;
        font-weight: $bold;
        border: 1px solid palette(black, 15);
        width: 48px;
        height: 48px;
        transition: all .2s;

        &:not(:last-child){
              border-right: none;
        }
        &:last-child{
          border-radius: 0px 4px 4px 0px;
        }
        &:first-child{
          border-radius:4px 0px 0px 4px;
        }

        &--Current {
            margin: 0px;
            background-color: $primary-color;
            border-color: $primary-color;
        }

        &--Dots {
            font-weight: $black;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:nth-last-child(2).Pagination__Item--Current{
            border-radius: 0px 4px 4px 0px;
        }

        &--Disabled + .Pagination__Item{
            border-radius: 4px 0px 0px 4px;
        }

        &:not(#{$root}__Item--Current):hover {
            background: $pagination-hover-color;
        }
    }

    &__Link {
        width: 100%;
        height: 100%;
        color: palette(black, 82);

        &:focus{
          outline:none;
        }

        #{$root}__Item--Current & {
            color: white;
        }
    }

    &__Arrow {
        height: 38px;
        margin: 0 auto;

        &:focus {
          outline: none;
        }
    }

    &__Chevron {
        height: 16px;

        svg{
          width: 10px;
          height: 10px;

          *{
              fill: $primary-color;
          }
        }
    }
}
