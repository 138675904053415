@import 'Styles/Tillskararakademin/includes';

/**
*
* Pagination
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Pagination.Base.module.scss';

.Pagination {
    $root: &;

    padding-top: 5px;
    padding-bottom: 15px;

    @include media(sm) {
        padding-top: 20px;
        padding-bottom: 28px;
    }

    &__List {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Item {
        margin: 0 3px;
        position: relative;

        &--Dots {
            width: 12px;
            color: palette(black, 22);
            font-weight: $medium;
        }
    }

    &__Link {
        position: relative;
        padding: 2px 10px;
        color: palette(black, 22);
        font-weight: $medium;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 5px;
            left: 5px;
            height: 2px;
            background: palette(black, 16);
            opacity: 0;
            transition: opacity .2s, background-color .2s;
        }

        #{$root}__Item--Current & {
            color: $text-color;
            font-weight: $bold;
            border: 2px solid palette(black);
        }
    }

    &__Arrow {
        height: 48px;
        margin: 0;

        @include media(sm) {
            margin: 0 10px;
        }
    }
}
