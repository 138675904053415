@import 'Styles/Medborgarskolan/includes';

/**
*
* CalendarList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CalendarList {
    $root: &;

    background: white;

    &__Container {
        @include u-wrap(site);

        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media(md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @include media(lg) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        #{$root}--EventSearch & {
            padding-top: 0;
            padding-bottom: 20px;

            @include media(md) {
                padding-top: 0;
                padding-bottom: 20px;
            }

            @include media(lg) {
                padding-top: 0;
                padding-bottom: 20px;
            }
        }
    }

    &__Title {
        margin-bottom: 24px;
        font-size: 3.4rem;
        font-weight: $bold;
        color: $blue;

        @include media(sm) {
            margin-bottom: 40px;
            font-size: 4.8rem;
        }

        #{$root}--EventSearch & {
            display: none;
        }
    }

    &__Header {
        display: none;

        @include media(sm) {
            padding-top: 30px;
            align-items: baseline;
            justify-content: space-between;
        }

        #{$root}--EventSearch & {
            display: block;

            @include media(sm) {
                display: flex;
            }
        }
    }

    &__ResultLabel {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 50px;
        padding: 24px 10px 12px 10px;
        color: $grey-70;
        font-size: 1.2rem;
        font-weight: $medium;
        text-align: center;

        @include media(sm) {
            padding: 0;
            border-bottom: 0;
            font-size: 1.4rem;
        }
    }

    &__ResultText {
        text-align: center;
        transition: opacity .2s;

        em {
            font-weight: $bold;
        }

        span {
            display: none;
            white-space: nowrap;
        }

        &--Keyword {
            :global(span.resultKeyword) {
                display: inline;
            }
        }

        &--City {
            :global(span.resultCity) {
                display: inline;

                em {
                    text-transform: capitalize;
                }
            }
        }

        #{$root}--Loading & {
            opacity: 0;
        }
    }

    &__Grid {
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        flex-wrap: nowrap;
        margin-bottom: 0;
        margin-left: -12px;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media(md) {
            flex-wrap: wrap;
            // overflow: hidden;
        }

        #{$root}--EventSearch & {
            flex-wrap: wrap;

            @include media(xs) {
                margin-left: -12px;
            }
        }
    }

    &__Link {
        @include u-button(primary);

        align-self: center;
        display: flex;
        align-items: center;
        margin-top: 28px;
        line-height: 1.4;

        svg {
            display: none;
        }

        @include media(sm) {
            justify-content: center;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 8px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--white.svg') no-repeat center center;
            background-size: contain;
            transition: transform .3s ease-in-out;
        }

        &:hover {
            &::after {
                transform: translateX(8px);
            }
        }

        #{$root}--EventSearch & {
            display: none;
        }
    }

    &__LinkText {
        display: inline-block;
    }

    &__Item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        width: calc(100% - 12px);
        margin-left: 12px;
        margin-bottom: 12px;

        @include media(xs) {
            width: calc(50% - 12px);
        }

        @include media(lg) {
            width: calc(25% - 12px);
        }

        #{$root}--Home & {
            display: flex;
            flex: 0 0 auto;
            width: calc(75% - 12px);
            margin-left: 12px;
            max-width: 297px;

            @include media(xs) {
                width: calc(40% - 12px);
            }

            @include media(md) {
                max-width: none;
                width: calc(25% - 12px);
            }

        }
    }

    &__NoResult {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 45px 15px;
        margin: 0;
        font-size: 1.6rem;
        text-align: center;

        @include media(md) {
            height: 340px;
            padding: 45px 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultBorder {
        display: none;
    }

    &__NoResultTitle {
        margin-bottom: 5px;
        font-size: 1.8rem;
        font-weight: $medium;

        @include media(md) {
            font-size: 2rem;
        }
    }

    &__NoResultFlag {
        margin-bottom: 10px;

        svg {
            width: 26px;
            height: 36px;
            fill: #BCBCBC;
        }
    }
}
