@import 'Styles/Dansskolanentre/includes';

/**
*
* Pagination
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Pagination.Base.module.scss';

.Pagination {
    $root: &;

    margin-top: 5px;
    margin-bottom: 15px;

    @include media(sm) {
        margin-top: 20px;
        margin-bottom: 28px;
    }

    @include media(lg) {
        margin-top: 44px;
        margin-bottom: 52px;
    }

    &__Item {
        margin: 0 3px;
        position: relative;

        &--Dots {
            width: 12px;
            height: 0;
            color: $grey-50;
            font-weight: $medium;
        }
    }

    &__Link {
        position: relative;
        padding: 2px 15px;
        color: $text-color;
        font-size: 1.8rem;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            border-radius: 100%;
            height: 40px;
            width: 40px;
            background-color: $red-5;
            opacity: 0;
            transition: opacity .2s, background-color .2s;
            z-index: -1;
        }

        &:hover {
            &::after {
                opacity: 1;
            }

            @media(hover: none) {
                &::after {
                    opacity: 0;
                }
            }
        }

        #{$root}__Item--Current & {
            color: white;

            &::after {
                background: $gradient-color;
                opacity: 1;
            }
        }
    }

    &__Arrow {
        height: 48px;
        margin: 0;

        @include media(sm) {
            margin: 0 10px;
        }
    }
}
