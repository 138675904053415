@import 'Styles/Kulturama/includes';

/**
*
* CalendarList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CalendarList {
    $root: &;
    background-color: palette(black, 3);

    &--EventSearch .CardCalendar {
        height: 100%;
    }

    &__Container {
        @include u-wrap(site);

        padding-top: 40px;
        padding-bottom: 20px;

        #{$root}--Home & {
            padding-top: 40px;
            padding-bottom: 50px;
            overflow: hidden;
        }

        @include media(md) {
            padding-top: 60px;
            padding-bottom: 20px;
        }

    }

    &__Title {
        margin-bottom: 15px;
        font-size: 2.4rem;
        font-weight: $bold;

        @include media(sm) {
            margin-bottom: 30px;
            font-size: 3rem;
            text-align: center;
        }

        #{$root}--Home & {
            margin-bottom: 0;
        }
    }

    &__Header {
        display: none;
    }

    &__Grid {
        display: flex;
        flex-wrap: wrap;

        #{$root}--Home & {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            flex-wrap: nowrap;
            margin-bottom: 0;
            margin-left: -12px;

            &::-webkit-scrollbar {
                display: none;
            }

            @include media(md) {
                flex-wrap: wrap;
                overflow: hidden;
                // margin-bottom: 28px;
            }
        }

        #{$root}--EventSearch & {
            @include media(xs) {
                margin-left: -12px;
            }
        }
    }

    &__Link {
        display: flex;
        align-items: center;
        color: palette(black, 95);
        font-size: 1.4rem;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 0.01rem;

        svg {
            margin-right: 10px;
            fill: black;
            transition: fill .2s;
        }

        @include media(sm) {
            justify-content: center;
            font-size: 1.8rem;
            text-align: center;

            svg {
                width: 18px;
                height: 14px;
            }
        }

        &:hover {
            svg {
                fill: palette(pink);
            }
        }
    }

    &__LinkText {
        position: relative;
        display: block;
        line-height: 1.3;
        transition: color .2s;

        #{$root}__Link:hover & {
            color: palette(pink);
        }
    }

    &__Item {
        width: 100%;
        margin-bottom: 12px;

        @include media(xs) {
            width: calc(50% - 12px);
            margin-left: 12px;
        }

        @include media(lg) {
            width: calc(25% - 12px);
        }

        #{$root}--Home & {
            display: flex;
            flex: 0 0 auto;
            width: calc(65% - 12px);
            margin-left: 12px;
            padding-top: 25px;
            padding-bottom: 25px;

            @include media(xs) {
                width: calc(40% - 12px);
                padding-top: 30px;
                padding-bottom: 30px;
            }

            @include media(md) {
                width: calc(25% - 12px);
                padding-top: 40px;
            }

        }

        #{$root}--EventSearch & {
            clip-path: polygon(100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0% 100%, 0 0);
        }

    }

    &__NoResult {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 45px 15px;
        margin: 0;
        font-size: 1.6rem;
        text-align: center;

        @include media(md) {
            height: 340px;
            padding: 45px 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultBorder {
        display: none;
    }

    &__NoResultTitle {
        margin-bottom: 5px;
        font-size: 1.8rem;
        font-weight: $medium;

        @include media(md) {
            font-size: 2rem;
        }
    }

    &__NoResultFlag {
        margin-bottom: 10px;

        svg {
            width: 26px;
            height: 36px;
            fill: #BCBCBC;
        }
    }
}
