@import 'Styles/Base/includes';

/**
*
* Pagination
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Pagination {
    $root: &;

    &__List {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Item {
        display: block;
        text-align: center;
        cursor: pointer;

        &__Arrow {
            margin: 0 5px;
        }

        &--Disabled {
            opacity: 0;
        }
    }

    &__Link {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        cursor: pointer;
        transition: transform .15s ease;

        @include media(sm) {
            width: 30px;
        }

        &--Prev {
            &:hover {
                transform: translateX(-2px);

                @media(hover: none) {
                    transform: translateX(0);
                }
            }
        }

        &--Next {
            &:hover {
                transform: translateX(2px);

                @media(hover: none) {
                    transform: translateX(0);
                }
            }

            svg {
                transform: rotate(180deg);
            }
        }
    }
}
