@import 'Styles/Medborgarskolan/includes';

/**
*
* Pagination
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Pagination.Base.module.scss';

.Pagination {
    $root: &;

    margin-top: 32px;
    padding-bottom: 32px;
    color: $blue;
    transition: opacity .2s;

    &--Loading {
        opacity: 0;
    }

    &__Item {
        margin: 0 4px;
        font-size: 1.6rem;
        font-weight: $bold;
        border: solid 2px $blue;
        border-radius: 100%;
        transition: background-color $transition;

        @include media(sm) {
            margin-left: 6px;
            margin-right: 6px;
        }

        &:hover {
            background-color: $blue;
        }

        &--Current {
            background-color: $blue;
        }

        &--Dots {
            align-self: flex-end;
            width: 17px;
            font-weight: $bold;
            letter-spacing: 2px;
            border: none;

            a {
                color: $blue;
            }

            &:hover {
                background-color: transparent;
            }
        }

        &--Arrow {
            border: none;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &__Link {
        width: 44px;
        height: 44px;
        color: $blue;
        border-radius: 50%;
        transition: color $transition;

        @include media(sm) {
            width: 52px;
            height: 52px;
        }

        #{$root}__Item--Current & {
            color: white;
        }

        #{$root}__Item:hover & {
            color: white;
        }
    }

    &__Arrow {
        // display: none;
        height: 48px;
        margin: 0;

        @include media(sm) {
            margin: 0 10px;
        }
    }

    &__Chevron {
        width: 24px;
        height: 24px;
        background: url('#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg') no-repeat center center;
        background-size: contain;
        transform: rotate(0deg);

        #{$root}__Arrow--Prev & {
            transform: rotate(180deg);
        }

        svg {
            display: none;
        }
    }
}
