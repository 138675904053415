@import 'Styles/Kulturama/includes';

/**
*
* Pagination
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Pagination.Base.module.scss';

.Pagination {
    $root: &;

    padding-top: 5px;
    padding-bottom: 15px;

    @include media(sm) {
        padding-top: 20px;
        padding-bottom: 28px;
    }

    &__Item {
        margin: 0 3px;
        position: relative;

        &--Dots {
            width: 12px;
            color: palette(black, 50);
            font-weight: $bold;
        }
    }

    &__Link {
        position: relative;
        padding: 5px 10px;
        color: palette(black, 50);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 5px;
            left: 5px;
            height: 2px;
            background: palette(black, 16);
            opacity: 0;
            transition: opacity .2s, background-color .2s;
        }

        &:hover {
            &::after {
                opacity: 1;
            }

            @media(hover: none) {
                &::after {
                    opacity: 0;
                }
            }
        }

        #{$root}__Item--Current & {
            color: $text-color;
            font-weight: $bold;

            &::after {
                background-color: palette(pink);
                opacity: 1;
            }
        }
    }

    &__Arrow {
        height: 48px;
        margin: 0;

        @include media(sm) {
            margin: 0 10px;
        }
    }

    &__Chevron {
        svg {
            height: 14px;
        }
    }
}
